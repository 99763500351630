<div class="p-2 d-flex flex-column justify-content-between h-100">
    <div class="spacer"></div>
    <div class="flex-1">
        <h1 class="mat-display-1 mb-3">Reset password</h1>
        <p class="mat-subheading-2 mb-5">
            We get it, stuff happens. Just enter your email address below
            and we'll send you a link to reset your password!
        </p>
    <form class="user ">
        <div class="form-group">
            <input name="email" type="email" class="form-control form-control-user" aria-describedby="emailHelp"
                placeholder="Email" [(ngModel)]="email" (focus)="errorMessage = ''">
        </div>
        <button (click)="forgotPassword()" class="btn btn-primary btn-user btn-block">
            Reset Password
        </button>
    </form>
    </div>
    <div class="spacer"></div>
    <div>
        <h5>Already have an account?</h5>
    
<hr>
<div class="mb-4">
    <button type="button" mat-stroked-button routerLink="/login">Login!</button>
</div>
</div>
</div>