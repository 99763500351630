import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Group } from 'backend/interfaces/group.interface';
import { DataService } from 'src/app/services/data.service';
import * as Parse from 'parse';
@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {
  categories: Group[];
  categoryCount;
  searchResult: Group[];
  searchPhrase = '';
  searchCategoryForm: FormGroup;
  faSearch = faSearch
  pageLimit = 100;
  page=0;
  constructor(private dataService: DataService, private router: Router, private fb: FormBuilder) {}

  loading = false;
  async ngOnInit(): Promise<void> {
    this.searchCategoryForm = this.fb.group({
      searchPhrase: ['']
    })
    this.loading = true;
    this.dataService.getGroups(this.pageLimit, this.page).then(result =>{
      this.categories = result;
    });

    this.dataService.groupCount$.subscribe(res => {
      this.categoryCount = res;
    })

    this.loading = false;
  }

  async search(){
    if(this.searchCategoryForm.controls['searchPhrase'].value !== ''){
    this.loading = true;
    this.searchPhrase = this.searchCategoryForm.controls['searchPhrase'].value;
    this.searchResult = this.categories.filter(j => {
      return j.name.toLowerCase().includes(this.searchPhrase.toLowerCase()) || j.description.toLowerCase().includes(this.searchPhrase.toLowerCase());
    })
    
    this.loading = false;
    }
  }

  clearSearch(){
    this.searchPhrase = '';
    this.searchResult = null;
    this.searchCategoryForm.controls['searchPhrase'].setValue('');
  }

  open(categoryId: Group){
    this.router.navigate(['category', categoryId])
  }

  goTo(route: string){
    this.router.navigate(['auth', 'register'])
  }

}
