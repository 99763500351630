import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../services/data.service';
import { faTrademark } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-public',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.scss']
})
export class PublicComponent implements OnInit {

  constructor(private router: Router, private dataService: DataService) { }
  faTM = faTrademark;
  ngOnInit(): void {
    // this.dataService.getUserCount();
    this.dataService.getGroupCount();
    this.dataService.getGroups(9, 0).then((result) => {
      this.dataService.groups.next(result);
    });
  }

  Login(): void{
    this.router.navigateByUrl('auth');
  }
}
