import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { faPaperPlane, faUser } from '@fortawesome/free-solid-svg-icons';
import { ParseClasses } from 'backend/enum/ParseClasses.enum';
import { ParsePointer } from 'backend/interfaces/ParsePointer';
import { Group } from 'backend/interfaces/group.interface';
import * as Parse from "parse";
import { ParsedUrlQuery } from 'querystring';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { BusinessDataService } from 'src/app/business/services/data.service';
import { ChatService } from 'src/app/services/chat.service';
import { DataService } from 'src/app/services/data.service';
@Component({
  selector: 'app-conversation',
  templateUrl: './conversation.component.html',
  styleUrls: ['./conversation.component.scss']
})
export class ConversationComponent implements OnInit, OnDestroy {
  @Input('recipient') recipient: Parse.User | Group;
  @Input('isBusiness') isBusiness: boolean;
  @Input('messageToGroup') messageToGroup: boolean;
  @Output('createNotificationToBusinesses') createNotificationToBusinesses: EventEmitter<any> = new EventEmitter(null);
  faUser = faUser;
  MessageObj = Parse.Object.extend('Messages');
  messages;
  loading = false;
  sending = false;
  subscription;
  faPaperPlane = faPaperPlane;
  query = new Parse.Query(ParseClasses.MESSAGES);
  currentUser = Parse.User.current().get('Name');
  senderId = Parse.User.current().id;
  senderQuery1 = new Parse.Query(ParseClasses.MESSAGES);
  recipientQuery1 = new Parse.Query(ParseClasses.MESSAGES);
  senderQuery2 = new Parse.Query(ParseClasses.MESSAGES);
  recipientQuery2 = new Parse.Query(ParseClasses.MESSAGES);
  conversationId = '';
  combinedQuery = Parse.Query.or(this.senderQuery1, this.senderQuery2);
  // combinedQuery = new Parse.Query(ParseClasses.MESSAGES);
  myInterval;
  messageForm: FormGroup = this.fb.group({
    message: ['']
  })
  @ViewChild('messageInput') messageInput: ElementRef;
  @Output() emitClose = new EventEmitter<Boolean>();
  constructor(private chatService: ChatService, private authService: AuthService, private businessDataService: BusinessDataService, private fb: FormBuilder, private router: Router) { }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.messages = null;
    this.isBusiness = this.router.url.includes('account');

    this.businessDataService.business$.subscribe(b => {
      if(!this.recipient){
      this.recipient = b
      }
      this.senderId = b?.id;
    });
    if (this.isBusiness) {

      this.recipientQuery1.equalTo('Recipient', this.recipient);

      this.recipientQuery1.find().then(r => {
        if (r) {
          this.conversationId = this.senderId + '-' + this.recipient.id;
          this.subscribeToLeadsLiveQuery();
        }
      }).catch(err => {
        this.conversationId = this.senderId + '-' + this.recipient.id;
        this.subscribeToLeadsLiveQuery();
      })
    } else {
      // console.log(this.recipient)
      // this.senderQuery1.equalTo('Sender', Parse.User.current().id);
      // this.recipientQuery1.equalTo('Business', this.recipient);
      // this.senderQuery2.equalTo('Sender', this.recipient);
      // this.recipientQuery2.equalTo('Business', Parse.User.current().id);
      // this.combinedQuery.first().then(r => {
      //   if (r) {
      //     this.conversationId = r.get('conversation');
      //     this.subscribeToLiveQuery();
      //   } else {
      //     this.conversationId = '';
      //   }
      // }).catch(err => {
      //   console.log(err)
        this.conversationId = Parse.User.current().id + '-' + this.recipient.id;
        this.subscribeToLiveQuery();
      // })
      // console.log(this.conversationId)
      this.loading = false;
    }

  }

  ngAfterViewInit(): void {
    this.messageInput?.nativeElement.focus();
    // this.myInterval = setInterval(() => {
    //   this.refresh();
    // }, 10000);
  }

  ngOnDestroy(): void {
    // console.log('destroy')
    clearInterval(this.myInterval);
  }
  createChat() {
    //   console.log(this.chatId)
    //   this.chatService.createChatSpace(this.user).then(res => {
    //     console.log(res);
    //   })
  }


  async subscribeToLiveQuery() {
    // console.log(this.recipient.id)
    // console.log(this.senderId)
    const conv1 = Parse.User.current().id + '-' + this.recipient.id;
    const conv2 = this.recipient.id + '-' + Parse.User.current().id;
    this.senderQuery1.equalTo('conversation', conv1);
    this.senderQuery2.equalTo('conversation', conv2);

    this.combinedQuery.ascending('createdAt');
    this.combinedQuery.limit(100);
    await this.combinedQuery.find().then(messages => {
      this.messages = new Set(messages);
      this.loading = false;
    }).catch(error => {
      alert(`Failed to retrieving objects, with error code: ${error.message}`);
    });
    this.subscription = await this.query.subscribe();
    this.subscription.on('open', message => {
      // console.log('trigger open')

      this.messages.forEach(t => {
        if (Parse.User.current().id === t.get('Recipient').id && t.get('read') === false) {
          // console.log(t)
          t.set('read', true);
          t.save();
        }
      });
    });
    this.subscription.on('create', message => {
      this.messages.forEach(t => {
        if (Parse.User.current().id === t.get('Recipient').id && t.get('read') === false) {
          // console.log(t)
          t.set('read', true);
          t.save();
        }
      });
      this.messages.add(message);
      // console.log('trigger create')
    });
    this.subscription.on('update', message => {
      // console.log(message)
    });
    this.subscription.on('leave', message => {
      // console.log('trigger leave')
    });
    this.subscription.on('delete', message => {
      this.messages.forEach(t => {
        if (t.id === message.id) {
          this.messages.delete(t);
        }
      });
    });
  }
  refresh() {
    this.subscribeToLiveQuery();
  }
  send() {
    this.sending = true;
    this.chatService.sendMessage(this.messageForm.controls['message'].value, this.recipient?.id, this.conversationId).then(res => {
      this.createNotificationToBusinesses.emit(res);
      // this.refresh();
      this.messageForm.controls['message'].setValue('');
      this.messageForm.reset();
      this.messageForm.markAsPristine();
      this.sending = false;
      // this.subscribeToLiveQuery();
    }).catch(err => console.log(err));
    // if (this.message) {
    //   const msg = new this.MessageObj();
    //   msg.save({Sender: Parse.User.current(), Message: this.message});
    // }
    // this.message = null;
  }
  startConversation(recipientId: string) {
    this.conversationId = Parse.User.current()?.id + '-' + recipientId;
    this.subscribeToLiveQuery();
  }



  async subscribeToLeadsLiveQuery() {
    {
      this.query.contains('conversation', this.recipient.id);
      this.query.ascending('createdAt');
      this.query.limit(50);
      await this.query.find().then(messages => {
        // messages = messages.filter(m => Parse.User.current().id !== m.get('Sender').id)
        this.messages = new Set(messages);
        // this.messages.forEach(m => console.log(m))
        this.loading = false;
      }).catch(error => {
        alert(`Failed to retrieving objects, with error code: ${error.message}`);
      });
      this.subscription = await this.query.subscribe();
      this.subscription.on('open', message => {
        this.messages.forEach(t => {
          if (Parse.User.current().id === t.get('Recipient').id && t.get('read') === false) {
            console.log(t)
            t.set('read', true);
            t.save();
          }
        });
      });
      this.subscription.on('create', message => {
        this.messages.forEach(t => {
          if (Parse.User.current().id === t.get('Recipient').id && t.get('read') === false) {
            console.log(t)
            t.set('read', true);
            t.save();
          }
        });
        this.messages.add(message);
        console.log('trigger create')
      });
      this.subscription.on('update', message => {
        console.log(message)
      });
      this.subscription.on('leave', message => {
        console.log('trigger leave')
      });
      this.subscription.on('delete', message => {
        this.messages.forEach(t => {
          if (t.id === message.id) {
            this.messages.delete(t);
          }
        });
      });
    }
  }
}
