import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faBuilding, faCalendar, faCheckDouble, faChevronDown, faChevronLeft, faChevronRight, faEnvelope, faHandshake, faInfo, faInfoCircle, faList, faPhotoVideo, faQuoteLeft, faSearch, faStar } from '@fortawesome/free-solid-svg-icons';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { DataService } from 'src/app/services/data.service';
import * as moment from 'moment';
import { Group } from 'backend/interfaces/group.interface';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  albumList = []
  loading = false;
  faNext = faChevronRight;
  faPrev = faChevronLeft;
  faDown = faChevronDown;
  faPhoto = faPhotoVideo;
  faShake = faHandshake;
  faEnvelope = faEnvelope;
  faInfo = faInfoCircle;
  faStar = faStar;
  faCalendar = faCalendar;
  faBuilding = faBuilding;
  faQuoteLeft = faQuoteLeft;
  faCheckList = faCheckDouble;
  CalendarView = CalendarView;
  categories = [];
  viewDate: Date = new Date();
  minTime = this.viewDate.getHours()-1;
  maxTime = this.viewDate.getHours()+3;
  categoryCount;
  userCount;
  ads;
  events: CalendarEvent[] = [
  ];

  searchResult: Group[];
  searchPhrase = '';
  searchCategoryForm: FormGroup;
  faSearch = faSearch
  constructor(private dataService: DataService, private router: Router, private fb: FormBuilder) {
    setInterval(() => {
      this.viewDate = new Date();
    }, 60000);
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.searchCategoryForm = this.fb.group({
         searchPhrase: ['']
       })
       this.loading = true;
    this.dataService.groups$.subscribe(async res => {
      this.categories = await res;
    })

    this.dataService.groupCount$.subscribe(res => {
      this.categoryCount = res;
    })
    // this.dataService.usercount$.subscribe(res => {
    //   this.userCount = res;
    // })

    //   await this.dataService.getFeaturedAds().then(async res => {
    //     this.ads = await res
    // });

    this.loading = false;
  }
  newAppointment(): void{
    this.router.navigateByUrl('appointment');
  }

  docuMerge(): void{
    this.router.navigateByUrl('pdf-worker');
  }

  goTo(route: string): void {
    this.router.navigate(['', route]);
  }

  open(categoryId: Group){
    this.router.navigate(['category', categoryId])
  }

  async search(){
    if(this.searchCategoryForm.controls['searchPhrase'].value !== ''){
    this.loading = true;
    this.searchPhrase = this.searchCategoryForm.controls['searchPhrase'].value;
    this.searchResult = this.categories?.filter(j => {
      return j.name.toLowerCase().includes(this.searchPhrase.toLowerCase());
    })
    this.loading = false;
    }
  }
  
}
