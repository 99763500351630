<div class="tp-wrapper">
    <div class="tp-box link" [@flipState]="flip" (click)="toggleFlip()">
    <div class="bg-image-container d-flex flex-column p-3 tp-box__side tp-box__front" [ngStyle]="{'background-image':'url'+'('+category.logo.source +')'}">
    <div class="flex-1 d-flex flex-column align-items-start">
    <mat-card-title class="link d-flex justify-content-between">
      <h3 class="mat-title bg-50perc-black text-white p-2 border-radius-5">
        {{ category.name }}
      </h3>
    </mat-card-title>
    <!-- <mat-card-subtitle>
      <span class="text-sm bg-50perc-white p-2 border-radius-5 mr-1" *ngIf="category.followers?.length > 0"><fa-icon [icon]="faUsers"></fa-icon> {{category.followers.length}} Follower<ng-container *ngIf="category.followers.length > 1">s</ng-container></span>
    </mat-card-subtitle> -->
  </div>
    <mat-card-content class="flex-1">
      <!-- <p id="category.id">{{category.description}}</p> -->
    </mat-card-content>
    <div class="d-flex justify-content-between align-items-center">
      <div class="flex-1 text-left d-flex justify-content-between">
        <button type="button" (click)="open(category.id)" mat-raised-button>View</button>

        <button mat-mini-fab color="primary"><fa-icon [icon]="faInfo"></fa-icon></button>
      </div>
    </div>
</div>
<div class=" p-3 tp-box__side tp-box__back" [ngStyle]="{'background-image':'url'+'('+category.logo.source +')'}">
    <div class="with-blur-backdrop">
            <h3 class="m-0 mat-title p-2 border-radius-5 text-black">
              {{ category.name }}
            </h3>
            <p class="bg-50perc-white text-sm ng-tns-c289-3 p-2">{{ category.description }}</p>

              <button class="text-secondary view" type="button" (click)="open(category.id)" mat-raised-button>View</button>
            <button (click)="follow(category)" class="text-secondary follow" mat-raised-button><fa-icon [icon]="faBinoculors"></fa-icon> Follow</button>

    </div>
</div>
</div>
    <!-- <mat-card-subtitle>{{card.subTitle}}</mat-card-subtitle> -->
</div>