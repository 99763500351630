<div
  class="d-flex justify-content-center loader flex-column align-items-center"
  *ngIf="loading"
>
  <div>
    <mat-spinner color="accent"></mat-spinner>
  </div>
</div>
<div class="main-wrapper">
  <div class="row m-0">
    <div class="col-md-12 m-0 pt-5 mt-5 pb-5 d-flex flex-column justify-content-center">
      <h1 class="mat-display-2 text-center mb-3">Empower your brand, Ignite your success</h1>
      <div class="d-flex justify-content-center flex-column">

          <p class="text-center text-gray">Empowering your online presence for unparalleled success"! Unlock the full potential of your brand with our innovative marketing and advertising solutions</p>
          <div class="row m-0 d-flex justify-content-center">
            <div class="col-md-6 d-flex flex-column justify-content-center">
            <button (click)="goTo('register')" class="m-3 mat-title p-3" mat-raised-button color="white"><mat-icon class="mr-3">add_business</mat-icon>List your Business For only R149 P/M</button>
              <button class="m-3 mat-title p-3" (click)="goTo('register')" mat-raised-button color="accent"><mat-icon class="mr-3">search</mat-icon>Create your FREE account</button>
            </div>
          </div>

      </div>
    </div>
    <!-- <div class="col-md-6 p-5">


        <div id="carouselExampleControls" class="h-100 bg-50perc-black carousel slide d-flex align-items-center flex-column relative" data-ride="carousel">
          <div class="carousel-inner h-100">
            <div class="h-100 carousel-item active text-center no-border" *ngIf="ads?.length < 1">                   
                <div class="d-flex flex-column" [style.backgroundImage]="'../assets/imgUpload.png'">
                  <div class="bg-50perc-black p-3 m-3">
                    <h1 class="text-white">Easily get your business offering infront of more people</h1>
                    <p class="text-white">{{body}}</p>
                  </div>
                </div>
              <button class="m-3 mb-5" mat-raised-button color="primary">Read more</button>
            </div>
            <ng-container *ngIf="ads?.length > 0">
              <div class="h-100 carousel-item no-border relative" *ngFor="let ad of ads; let i = index" [ngClass]="{'active': i === 0}" [ngStyle]="{'background-color':'#'+ad.adColor?.hex}">
                <h3 class="m-0 text-center p-2"><a>{{ad.business.get('Name')}}</a></h3>
                  <div class="h-100 advertensie d-flex flex-column" [ngStyle]="{'background-image':'url'+'('+ad.image1.source +')'}">
                    <div class="bg-50perc-black p-3 m-3">
                      <h1 class="text-white">{{ad.title}}</h1>
                      <p class="text-white">{{ad.body}}</p>
                    </div>
                  </div>

              </div>
            </ng-container>
          </div>
          <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
            <fa-icon class="text-primary" [icon]="faPrev" size="2x"></fa-icon>
            <span class="sr-only">Previous</span>
          </a>
          <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
            <fa-icon class="text-primary" [icon]="faNext" size="2x"></fa-icon>
            <span class="sr-only">Next</span>
          </a>
          <svg class="absolute-bottom" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#b18731" fill-opacity="1" d="M0,128L48,138.7C96,149,192,171,288,186.7C384,203,480,213,576,192C672,171,768,117,864,128C960,139,1056,213,1152,240C1248,267,1344,245,1392,234.7L1440,224L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
          <a style="z-index: 999;position: absolute;bottom: 10px; left: 10px;" class="text-center link text-white">Do you want to showcase your brand here?</a>
        </div>

    </div> -->
  </div>
  <div class="row bg-black m-0 p-5">
    <div class="col-md-12 p-3">
      <h1 class="text-center text-white">
        <span class="mat-display-2 text-primary">The Unrivalled Platform</span> <br>Your Exclusive Gateway to Visibility and Discovery across <span [countUp]="categoryCount">0</span>  Categories!
      </h1>
      <!-- <form [formGroup]="searchCategoryForm" (ngSubmit)="search()" class="d-flex">
        <div appearance="filled" class="d-flex align-items-center flex-1 bg-light p-2 ml-3 mr-3 border-radius-5">
            <fa-icon dis matPrefix size="2x" [icon]="faSearch"></fa-icon>
            <input placeholder="Search" class="searchInput mat-display-1 mb-0 ml-3"  name="searchPhrase" formControlName="searchPhrase"/>
        </div>
        <button type="submit" mat-raised-button color="primary" class="d-flex align-items-center">SEARCH</button>
    </form> -->
      <!-- <h1 class="text-white text-center"><span [countUp]="userCount" class="mr-3">0</span>Active members</h1> -->
    </div>
    <div *ngIf="!categories || categories.length < 1" class="row">
      <div class="col-md-4 mb-4 col-sm-6 col-xs-12 p-3">
        <app-ghost-rect></app-ghost-rect>
      </div>
      <div class="col-md-4 mb-4 col-sm-6 col-xs-12 p-3">
        <app-ghost-rect></app-ghost-rect>
      </div>
      <div class="col-md-4 mb-4 col-sm-6 col-xs-12 p-3">
        <app-ghost-rect></app-ghost-rect>
      </div>
      <div class="col-md-4 mb-4 col-sm-6 col-xs-12 p-3">
        <app-ghost-rect></app-ghost-rect>
      </div>
      <div class="col-md-4 mb-4 col-sm-6 col-xs-12 p-3">
        <app-ghost-rect></app-ghost-rect>
      </div>
      <div class="col-md-4 mb-4 col-sm-6 col-xs-12 p-3">
        <app-ghost-rect></app-ghost-rect>
      </div>
    </div>

      <div *ngFor="let category of categories" class="col-md-4 mb-4 col-sm-6 col-xs-12 p-3">
        <app-flip-card (emitFollow)="goTo('register')" (emitOpen)="open($event)" [category]="category"></app-flip-card>
      </div>

      <div class="col-md-12 p-3">
      <mat-card class="bg-secondary text-white">
        <mat-card-content class="d-flex flex-column justify-content-between">
          <h3 class="mat-display-1 text-center">Stay ahead of the curve with our data driven strategies tailored to your unique goals and audience.</h3>
          <div>
            <p class="text-center">The ADVIBE network is growing daily, bringing you trusted
              business in a variety of industries.</p>
            <button (click)="goTo('categories')" mat-raised-button color="accent" class="w-100 bg-black text-white">
              See all categories
            </button>
          </div>
        </mat-card-content>
      </mat-card>

    </div>
  </div>

  <div class="row m-0 bg-primary m-0 pt-5 pb-5">
    <div class="m-0 row justify-content-center">
      <div class="text-white mt-5 flex-wrap col-md-10 d-flex justify-content-around align-items-center">
      <div class="bg-black p-3 border-radius-5"><fa-icon size="3x" class="text-white" [icon]="faShake"></fa-icon></div>
      <div class="p-3 border-radius-5 mat-display-1 flex-1 m-0">ADVIBE is including everyone</div>
      <h2  class="flex-1 text-dark">No matter what the size of your business is, you deserve a platform to showcase your business and connect to a network of trusted businesses.</h2>
      </div>
      <div class="text-white col-md-8 d-flex pt-5 pb-5 mb-5 flex-column justify-content-center align-items-center">
        <h3>Do you have an idea about a new feature you would love to see?</h3>
        <button routerLink="/contact" mat-raised-button color="accent" class="bg-black text-white">
          Give feedback
        </button>
      </div>
    </div>
  </div>
  <div class="row m-0 bg-black">
    <!-- <div class="col-10" style="margin: -80px auto;">
    <mat-card class="bg-light">
      <mat-card-content class="d-flex justify-content-between">
        <div class="w-100 scrollable max-h-500">
          <mat-card class="mb-3">
            <mat-card-title>Testimonial Card</mat-card-title>
            <mat-card-content>
              <p>Some Nice text written by customers About ADVIBE...</p>
            </mat-card-content>
          </mat-card>
          <mat-card class="mb-3">
            <mat-card-title>Testimonial Card</mat-card-title>
            <mat-card-content>
              <p>Some Nice text written by customers About ADVIBE...</p>
            </mat-card-content>
          </mat-card>
          <mat-card class="mb-3">
            <mat-card-title>Testimonial Card</mat-card-title>
            <mat-card-content>
              <p>Some Nice text written by customers About ADVIBE...</p>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="p-5">
          <p>
            <fa-icon class="text-primary" [icon]="faStar" ></fa-icon>
            <fa-icon class="text-primary" [icon]="faStar" ></fa-icon>
            <fa-icon class="text-primary" [icon]="faStar" ></fa-icon>
            <fa-icon class="text-primary" [icon]="faStar" ></fa-icon>
            <fa-icon class="text-primary" [icon]="faStar" ></fa-icon>
          </p>
          <h2 class="mat-display-2">Our community loves ADVIBE</h2>
          <button (click)="goTo('categories')" mat-raised-button color="accent" class="bg-black text-white">
            See all categories
          </button>
        </div>
      </mat-card-content>
    </mat-card>
  </div> -->
  <!-- <div class="col-md-10" style="margin: 150px auto;">
    <div class="flex-wrap d-flex pt-5 pb-5 mb-5 justify-content-center align-items-center">
      <div class="bg-light p-3 border-radius-5"><fa-icon size="2x" [icon]="faEnvelope"></fa-icon></div>
      <h1 class="text-white flex-1 m-3">Subscribe to our newsletter to get the latest </h1>
      <div class="d-flex flex-1 flex-wrap">
        <input matInput class="flex-1 bg-dark text-white border-radius-5 p-3 mr-1">
      <button mat-raised-button color="primary" class="text-white">
        Subscribe
      </button>
      </div>
  </div>
  </div> -->
  </div>
</div>
