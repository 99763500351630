<div
  class="d-flex justify-content-center loader flex-column align-items-center"
  *ngIf="loading"
>
  <div>
    <mat-spinner color="accent"></mat-spinner>
  </div>
</div>
<ng-container *ngIf="!loading && category">
    <div class="row m-0 h-100">
      <div class="col-md-3 p-3 d-flex flex-column" [ngStyle]="{'background-image':'url'+'('+category.logo.source +')'}">
        <!-- <div class="row m-0 justify-content-center"> -->
          <!-- <div class="cover relative col-md-12 text-center p-5 justify-content-center"> -->
            <button mat-raised-button routerLink="/categories" color="primary" class="fab-top fab-top-left text-white">back</button>
            <div class="d-flex flex-column h-100 justify-content-between">
            <div class="d-flex flex-column justify-content-center align-items-center mt-5 mb-2">
              <h1 class="p-2 bg-50perc-black text-white">{{category.name}}</h1>
              <p class="p-2 bg-50perc-white">{{category.description}}</p>
              <!-- <span class="text-sm bg-50perc-white p-2 border-radius-5" *ngIf="category.followers?.length > 0"><fa-icon [icon]="faUsers"></fa-icon> {{category.followers.length}} Follower<ng-container *ngIf="category.followers.length > 1">s</ng-container></span> -->
              <span class="text-sm bg-50perc-white p-2 border-radius-5" *ngIf="category.businessess?.length > 0"><fa-icon [icon]="faBuilding"></fa-icon> {{category.businessess.length}} Businesses<ng-container *ngIf="category.businessess.length > 1">s</ng-container></span>
              </div>
              <div class="d-flex justify-content-center flex-column">

                <div id="carouselExampleControls" class="bg-50perc-black carousel slide border-primary d-flex align-items-center flex-column" data-ride="carousel">
                  <div class="carousel-inner">
                    <div class="carousel-item active text-center no-border" *ngIf="ads.length < 1">
                      <!-- <app-ad [title]="'Sample Ad'" [body]="'Get your business offering infront of more people easily'" [image1]=""></app-ad> -->                      
                        <div class="d-flex flex-column" [style.backgroundImage]="'../assets/imgUpload.png'">
                          <div class="bg-50perc-black p-3 m-3">
                            <h1 class="text-white">Easily get your business offering infront of more people</h1>
                            <p class="text-white">{{body}}</p>
                          </div>
                        </div>
                      <button class="m-3 mb-5" mat-raised-button color="primary">Read more</button>
                    </div>
                    <ng-container *ngIf="ads.length > 0">
                      <div class="carousel-item no-border relative" *ngFor="let ad of ads; let i = index" [ngClass]="{'active': i === 0}">
                        <h3 class="m-0 text-center p-2"><a>{{ad.business.get('Name')}}</a></h3>
                        <a class="top-right text-center link text-white" (click)="viewAds(ads)">View ads</a>
                        <!-- <app-ad [title]="ad.title" [body]="ad.body" [image1]="ad.image1"></app-ad> -->
                          <div class="advertensie d-flex flex-column" [style]="" [ngStyle]="{'background-image':'url'+'('+ad.image1.source +')'}">
                            <div class="bg-50perc-black p-3 m-3">
                              <h1 class="text-white">{{ad.title}}</h1>
                              <p class="text-white">{{ad.body}}</p>
                            </div>
                          </div>
  
                      </div>
                    </ng-container>
                  </div>
                  <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                  </a>
                  <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                  </a>
                  <svg class="absolute-bottom" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#b18731" fill-opacity="1" d="M0,128L48,138.7C96,149,192,171,288,186.7C384,203,480,213,576,192C672,171,768,117,864,128C960,139,1056,213,1152,240C1248,267,1344,245,1392,234.7L1440,224L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
                </div>
            </div>
        </div>
        <!-- </div> -->
      </div>
      <div class="col-md-9 p-0">
        <div class="bg-filter" [ngStyle]="{'background-image':'url'+'('+category.logo.source +')'}"></div>
        <ng-container *ngIf="category.businessess.length < 1; else showBusinesses">
          <div class="col-md-12 h-100 d-flex justify-content-center align-items-center flex-column">
          <h1 class="text-center">Be the first to load your business in this category</h1>
          <button mat-raised-button color="accent" (click)="goTo('login')">Add a business</button>
          </div>
        </ng-container>
        <ng-template #showBusinesses>
          <mat-tab-group>
            <mat-tab label="Businesses">
              <div class="row m-0 p-3">
                <div class="col-md-4 d-flex" *ngFor="let b of category.businessess">
                  <mat-card class="flex-column d-flex p-0 mb-3 hover-shadow w-100 link" (click)="viewBusiness(b)">
                    <mat-card-content class="p-3 bg-50perc-white card-body">
                      <span class="card-title">{{ b.Name }}</span>
                      <p class="mb-0">{{b.Address}}</p>
                      <div class="d-flex flex-wrap justify-content-center">
                        <div class="flex-1 d-flex flex-column justify-content-center">
                          <a [href]="b.Website" target="_blank">{{b.Website}}</a>
                          <a [href]="'mailto:'+b.Email" target="_blank">{{b.Email}}</a>
                          <a class="mt-2 text-primary" routerLink="/register" target="_blank"><b>View contact number</b></a>
                        </div>
                        <div class="d-flex align-items-center link justify-content-start">
                          <div
                          *ngIf="(b && !b?.Logo) || b?.Logo?.source === ''"
                          class="card-image md text-white"
                        >
                          <fa-icon size="2x" [icon]="faImage" class="text-white"></fa-icon>
                        </div>
                        <div class="md card-image d-flex justify-content-center flex-column"
                          *ngIf="b && b?.Logo && b?.Logo?.source !== ''"
                        >
                          <img class="border-radius-5" [src]="b.Logo.source" />
                        </div>
                      </div>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="Ads">
              <div class="row m-0">
              <div class="col-md-4 mt-3 border-primary" *ngFor="let ad of ads; let i = index">
                  <div class="h-100 link d-flex flex-column" (click)="viewBusinessFromAd(ad.business.id)">
                  <app-ad [title]="ad.title" [body]="ad.body" [image1]="ad.image1"></app-ad>
                  <div class="flex-1 link mb-3 bg-50perc-white d-flex justify-content-between m-0 p-2 align-items-center">
                    <div>
                      <span class="mat-title">{{ad.business.get('Name')}}</span> 
                      <p>{{ad.body}}</p>
                    </div>
                    <fa-icon [icon]="faCaretRight"></fa-icon></div>
                  </div>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </ng-template>
        </div>
    </div>
</ng-container>