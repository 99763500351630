
    
   <div class="d-flex justify-content-center loader flex-column align-items-center" *ngIf="loading">
    <div>
      <mat-spinner color="accent"></mat-spinner>
    </div>
  </div>
    <div *ngIf="!loading" class="h-100 d-flex flex-column cover" [ngStyle]="{'background-image':'url'+'('+(business.Logo? business.Logo : business.ProfilePicture).source +')'}">
        <button (click)="back()" class="text-white" mat-flat-button color="accent"><fa-icon [icon]="faPrev"></fa-icon> BACK</button>
    <div class="flex-1 justify-content-center d-flex flex-column p-3 relative m-0">
        <div class="row text-center justify-content-center m-0 mb-3">
            <div class="p-4 col-12">
              <h1 class="bg-50perc-black text-white">{{business.Name}}</h1>
              <ng-container *ngIf="loggedIn">
              <button color="accent" class="text-white" mat-raised-button *ngIf="!isFavorite" (click)="favouriteBusiness(business)"><fa-icon [icon]="faStar"></fa-icon>Favourite</button>
              <button color="accent" mat-stroked-button *ngIf="isFavorite" (click)="unFavouriteBusiness(business.id)"><fa-icon [icon]="faStar"></fa-icon>Unfavourite</button>
            </ng-container>
              </div>

                <div class="col-md-4 mb-3">
                    <div class="bg-50perc-white h-100 border-primary p-3 text-center d-flex flex-column justify-content-center align-items-center ">
                    <fa-icon size="2x" [icon]="faInfo"></fa-icon>
                                  <p>{{business.About}}</p>
                                  <a [href]="business.Website" target="_blank">{{business.Website}}</a>
                                  </div>
                </div>
                <div class="col-md-4 mb-3">
                    <div class="bg-50perc-white h-100 border-primary p-3 text-center d-flex flex-column justify-content-center align-items-center ">
                    <fa-icon size="2x" [icon]="faPin"></fa-icon>
                                  <p class="mb-4">{{business.Address}}</p>
                                  <fa-icon size="2x" [icon]="faPhone"></fa-icon>
                                  <p>{{business.Mobile}}</p>
                </div>
                </div>
                <div class="col-md-4 mb-3" *ngIf="loggedIn">
                    <div class="info-card scrollable bg-50perc-white h-100 border-primary p-3 text-center d-flex flex-column justify-content-center align-items-center ">
                      Chat with {{business.Name}}
                                  <app-conversation *ngIf="business" [recipient]="business"></app-conversation>
                </div>
                </div>

        </div>
        <div class="flex-1 row m-0" *ngIf="ads && ads.length > 0">
            <div class="col-md-12">
                        <div id="carouselExampleControls2" class="h-100 bg-50perc-black carousel slide d-flex align-items-center flex-column relative" data-ride="carousel">
                            <div class="carousel-inner h-100">
                                <div class="h-100 carousel-item no-border relative" *ngFor="let ad of ads; let i = index" [ngClass]="{'active': i === 0}" [ngStyle]="{'background-color':'#'+ad.adColor?.hex}">
                                  <h3 class="bg-50perc-white text-center p-2"><a>Ads by {{ad.business.get('Name')}}</a></h3>
                                  
                                  <!-- <app-ad [title]="ad.title" [body]="ad.body" [image1]="ad.image1"></app-ad> -->
                                    <div class="h-100 advertensie d-flex flex-column" [ngStyle]="{'background-image':'url'+'('+ad.image1.source +')'}">
                                      <div class="bg-50perc-black p-3 m-3">
                                        <h1 class="text-white">{{ad.title}}</h1>
                                        <p class="text-white">{{ad.body}}</p>
                                      </div>
                                    </div>
                  
                                </div>
                            </div>
                            <a class="carousel-control-prev" href="#carouselExampleControls2" role="button" data-slide="prev">
                              <fa-icon class="text-primary" [icon]="faPrev" size="2x"></fa-icon>
                              <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" href="#carouselExampleControls2" role="button" data-slide="next">
                              <fa-icon class="text-primary" [icon]="faNext" size="2x"></fa-icon>
                              <span class="sr-only">Next</span>
                            </a>
                          </div>
            </div>
        </div>
        </div>
    </div>

