
<div class="main-wrapper h-100 d-flex flex-column">
<mat-drawer-container autosize (backdropClick)="close()" [hasBackdrop]="sidenav.opened">
    
    <mat-drawer #sidenav mode="over" position="end">
        <mat-toolbar class="d-flex justify-content-between drawer-header">
            <h6>{{sidenavService.sidenavAction}}</h6>
            <div class="spacer"></div>
            <button mat-mini-fab color="primary" aria-label="Cancel" (click)="sidenavService.close()">
              <fa-icon class="text-white" [icon]="faTimes"></fa-icon> 
            </button>
          </mat-toolbar>

          <div class="d-flex flex-column justify-content-start p-3 scrollable">
            <mat-card style="max-width: 500px;" class="link mb-3">
                <a target="_blank" href="https://youtu.be/Z32XRaWL2QU?si=18k7qGyQjCuP4idP">
                <mat-card-header>
                    <mat-card-title>Create Free User Account</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                        <img class="img-fluid" src="./../../assets/FreeAccount.png">
                </mat-card-content>
            </a>
            </mat-card>

            <mat-card style="max-width: 500px;" class="link mb-3">
                <a target="_blank" href="https://youtu.be/Ybi7cHJvZ8E?si=pmvBPL3PnG5dBRww">
                <mat-card-header>
                    <mat-card-title>Add and Follow Categories</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                        <img class="img-fluid" src="./../../assets/AddAndFollow.png">
                </mat-card-content>
                </a>
            </mat-card>

            <mat-card style="max-width: 500px;" class="link mb-3">
                <a target="_blank" href="https://youtu.be/91xP7esxCJs?si=kvGgom5hOF6ZWOe7">
                <mat-card-header>
                    <mat-card-title>Interact with a business</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                        <img class="img-fluid" src="./../../assets/InteractAndComms.png">
                </mat-card-content>
                </a>
            </mat-card>


            <mat-card style="max-width: 500px;" class="link mb-3">
                <a target="_blank" href=" https://youtu.be/YsUL172enHo?si=b1spQLOF3YFV4msQ">
                <mat-card-header>
                    <mat-card-title>Setting up your business account</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                        <img class="img-fluid" src="./../../assets/SetupBusiness.png">
                </mat-card-content>
                </a>
            </mat-card>
            <mat-card style="max-width: 500px;" class="link mb-3">
                <a target="_blank" href="https://youtu.be/9mren4SKfZ0?si=CqQYV5p2Fa5r9XjL">
                <mat-card-header>
                    <mat-card-title>How To Create Ads On Advibe</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                        <img class="img-fluid" src="./../../assets/CreateAds.png">
                </mat-card-content>
                </a>
            </mat-card>
            <mat-card style="max-width: 500px;" class="link mb-3">
                <a target="_blank" href="https://youtu.be/SXcIq2z89ks?si=sNKY_yW5_COJgmNN">
                <mat-card-header>
                    <mat-card-title>View and favorite a Business</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                        <img class="img-fluid" src="./../../assets/ViewAndFavorite.png">
                </mat-card-content>
                </a>
            </mat-card>
            <a href="" target="_blank"></a>
            
          </div>
    </mat-drawer>
    <mat-drawer-content>
        
            <mat-toolbar class="pt-3 pb-3 d-flex justify-content-between bg-white">
                <div class="pl-3 d-flex align-items-start" routerLink="home">
                    ADVIBE <fa-icon class="ml-1 mt-0 text-sm" [icon]="faTM">tm</fa-icon>
                </div>
                <div class="spacer"></div>
                <div>
                    <a target="_blank" href="https://www.facebook.com/profile.php?id=61565956312079&mibextid=ZbWKwL"><img src="./../../assets/icons8-facebook.svg"/></a>
                    <a target="_blank" href="https://www.instagram.com/advibe_sa?igsh=MTA5b2Rlc3lldmNldQ=="><img src="./../../assets/icons8-instagram.svg"/></a>
                    <a target="_blank" href="https://youtube.com/@advibe.platform?si=rFD_CdjzdQDv6bet"><img src="./../../assets/icons8-youtube-48.svg"/></a>
                </div>
                <div class="spacer"></div>
                <div class="desktop">
                    <button mat-button routerLink="home" routerLinkActive="active">
                        Home
                    </button>
                    <button mat-button routerLink="about" routerLinkActive="active">
                        About
                    </button>
                    <button mat-button routerLink="categories" routerLinkActive="active">
                        Categories
                    </button>
                    <button mat-button routerLink="contact" routerLinkActive="active">
                        Contact us
                    </button>
                </div>
                <div class="desktop">
                <button color="primary" mat-button (click)="Login()">Register/Login</button>
                </div>
                <div class="mobile">
                    <button mat-stroked-button color="accent" [matMenuTriggerFor]="menu">MENU</button>
                    <mat-menu #menu="matMenu">
                            <button mat-menu-item routerLink="home" routerLinkActive="active">
                                Home
                            </button>
                        <button mat-menu-item routerLink="about" routerLinkActive="active">
                            About
                        </button>
                        <button mat-menu-item routerLink="categories" routerLinkActive="active">
                            Categories
                        </button>
                        <button mat-menu-item routerLink="contact" routerLinkActive="active">
                            Contact us
                        </button>
                        <button mat-menu-item (click)="Login()">Login</button>
                    </mat-menu>
                </div>
        
            </mat-toolbar>
            <div class="p-0 dashboard-container flex-1">
                <router-outlet></router-outlet>
            </div>
            <div class="row bg-black text-white m-0 p-4">
                <div class="col-md-4 p-3">
                    <div class="d-flex flex-column justify-content-between">
                        <button mat-button routerLink="auth" class="d-flex justify-content-between">
                            Login/Register <mat-icon class="text-white">arrow_right</mat-icon>
                        </button>
                        <button mat-button routerLink="categories" class="d-flex justify-content-between">
                            Browse categories <mat-icon class="text-white">arrow_right</mat-icon>
                        </button>
                    </div>
                </div>
                <div class="col-md-4 p-3">
                    <div class="d-flex flex-column justify-content-between">
                        <button mat-button routerLink="about" class="d-flex justify-content-between">
                            About ADVIBE <mat-icon class="text-white">arrow_right</mat-icon>
                        </button>
        
                        <button mat-button routerLink="contact" class="d-flex justify-content-between">
                            Contact Us <mat-icon class="text-white">arrow_right</mat-icon>
                        </button>
                    </div>
                </div>
                <div class="col-md-4 p-3">
                    <div class="d-flex flex-column justify-content-between">
                        <button mat-button routerLink="terms-and-conditions" class="d-flex justify-content-between">Terms of Service <mat-icon class="text-white">arrow_right</mat-icon></button>
                        <button mat-button routerLink="privacy-policy" class="d-flex justify-content-between">Privacy Policy <mat-icon class="text-white">arrow_right</mat-icon></button>
                    </div>
                </div>
            </div>
            <div class="bg-black text-center text-white d-flex align-items-center justify-content-center p-3" style="background-color: #000000 !important;">
                All rights Reserved <mat-icon>copyright</mat-icon> ADVIBE <fa-icon class="ml-1 mb-2 text-sm" [icon]="faTM">tm</fa-icon>
            </div>

    </mat-drawer-content>
</mat-drawer-container>


</div>