import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { faPlus, faUndo } from '@fortawesome/free-solid-svg-icons';
import { SideNavService, SidenavActions } from 'src/app/admin/services/side-nav.service';
import { BusinessDataService } from 'src/app/business/services/data.service';
import { DataService } from 'src/app/services/data.service';
import { ProfilePictureDialogComponent } from '../profile-picture-dialog/profile-picture-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import * as Parse from "parse";
@Component({
  selector: 'app-marketing',
  templateUrl: './marketing.component.html',
  styleUrls: ['./marketing.component.scss']
})
export class MarketingComponent implements OnInit {
  @ViewChild('profilePic') profilePic: ElementRef;
  businesses = [];
  business;
  faUndo = faUndo;
  faPlus = faPlus;
  loading = false;
  leads;
  favoritedBy = [];
  constructor(private dataService: BusinessDataService, private sideNavService: SideNavService, private dialog: MatDialog) { }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.businesses = [];
    this.dataService.business$.subscribe(res => {
      if (res) {
        this.business = res;
        const usersArray = this.business.FavoritedBy;
      Parse.Cloud.run('getBusinessFavoritedBy', { usersArray }).then(res => {
        this.favoritedBy = res;
      })
      }
    });
    this.dataService.businesses$.subscribe(res => {
      if (res) {
        this.businesses = res;
      }
    });
    if(this.business)
      this.getLeadsForCategory(this.business.Category.id);
    this.loading = false;
  }

  createBusiness(){
    this.sideNavService.open(SidenavActions.NewBusiness);
  }

  async getAdsForBusiness(id: string){
    this.loading = true;
    await this.dataService.getAdsByBusinessId(id);
    this.loading = false;
  }

  async getLeadsForCategory(categoryId: string){
    this.loading = true;
    this.leads = await this.dataService.getLeadsForCategory(categoryId);
    this.loading = false;
  }

  openProfilePictureDialog(src?: any): void {
    const dialogRef = this.dialog.open(ProfilePictureDialogComponent, {
      data: {title: 'Upload Picture', parentId: this.business.id, currentPicture: src ? src : ''},
      width: '60%'
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.profilePic.nativeElement.src = result;
      } else {
        this.dialog.closeAll();
      }
    });
  }

}
