import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../services/data.service';
import { faTimes, faTrademark } from '@fortawesome/free-solid-svg-icons';
import { SideNavService } from '../admin/services/side-nav.service';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-public',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.scss']
})
export class PublicComponent implements OnInit, AfterViewInit {

  constructor(private router: Router, private dataService: DataService, public sidenavService: SideNavService) { }
  faTM = faTrademark;
  faTimes = faTimes;
  @ViewChild('sidenav') sidenav: MatSidenav;
  ngOnInit(): void {
    // this.dataService.getUserCount();
    this.dataService.getGroupCount();
    this.dataService.getGroups(8, 0).then((result) => {
      this.dataService.groups.next(result);
    });
  }

  ngAfterViewInit(): void {
    this.sidenavService.setSidenav(this.sidenav);
  }

  Login(): void{
    this.router.navigateByUrl('auth');
  }

  close() {
    return this.sidenavService.close();
}

}
